import React, { useState, useEffect } from 'react';
import SEO from '../components/SEO/SEO';
import Hero from '../components/Hero/Hero';
import { graphql, Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';

import '../scss/index.scss';
import '@splidejs/react-splide/css/core';

const Home = ({ data }) => {
  const { allSectionsJson: sections } = data;
  const { allAreaJson: area } = data;
  const { allChaletJson: chalet } = data;
  const { allChaletImagesJson: chaletImages } = data;
  const { allFaqJson: faq } = data;
  const { allFaqsJson: faqs } = data;
  const { allReviewsJson: reviews } = data;

  const [openFaq, setOpenFaq] = useState('');

  const useScrollHandler = () => {
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
      const onScroll = () => {
        const scrollCheck = window.scrollY > 10;
        setScroll(scrollCheck);
      };

      document.addEventListener('scroll', onScroll);
      return () => {
        document.removeEventListener('scroll', onScroll);
      };
    }, [scroll, setScroll]);

    return scroll;
  };

  const scroll = useScrollHandler();

  return (
    <>
      <SEO
        title="Home"
        description="Een prachtige camping in Zeeland. Mooi gelegen bij Zonnemaire en op steenworp afstand van Brouwershaven en Zierikzee."
        keywords={['Camping', 'Oud-Bommenede']}
      />

      <Hero isScrolled={scroll} />

      {sections && sections.nodes.map((section) => {
        return (
          <>
            <section className="section" id={section.name.toLowerCase()}>
              <div className="wrapper">
                <header className="section__header">
                  <h3>{section.title}</h3>
                  <ReactMarkdown children={section.content} />
                </header>
                <div className="grid">
                  <div className="block block--image">
                    <Splide hasTrack={ false } aria-label={section.block_one.eyebrow}>
                      <SplideTrack>
                        {section.block_one.galleryImages && section.block_one.galleryImages.map((item) => {
                          return (
                            <SplideSlide>
                              <img src={item} height={480} alt={item} />
                            </SplideSlide>
                          )
                        })}
                      </SplideTrack>
                      <div className="splide__arrows">
                        <button className="splide__arrow splide__arrow--prev">
                          <span className="material-icons-round">chevron_left</span>
                        </button>
                        <button className="splide__arrow splide__arrow--next">
                          <span className="material-icons-round">chevron_right</span>
                        </button>
                      </div>
                    </Splide>
                  </div>
                  <div className="block block--green block--center">
                    <h5>{section.block_one.eyebrow}</h5>
                    <h4>{section.block_one.title}</h4>
                    <ReactMarkdown children={section.block_one.content} />
                  </div>
                  <div className="block block--green block--center">
                    <h5>{section.block_two.eyebrow}</h5>
                    <h4>{section.block_two.title}</h4>
                    <ReactMarkdown children={section.block_two.content} />
                  </div>
                  <div className="block block--image">
                    <Splide hasTrack={ false } aria-label={section.block_two.eyebrow}>
                      <SplideTrack>
                        {section.block_two.galleryImages && section.block_two.galleryImages.map((item) => {
                          return (
                            <SplideSlide>
                              <img src={item} height={480} alt={item} />
                            </SplideSlide>
                          )
                        })}
                      </SplideTrack>
                      <div className="splide__arrows">
                        <button className="splide__arrow splide__arrow--prev">
                          <span className="material-icons-round">chevron_left</span>
                        </button>
                        <button className="splide__arrow splide__arrow--next">
                          <span className="material-icons-round">chevron_right</span>
                        </button>
                      </div>
                    </Splide>
                  </div>
                </div>
              </div>
            </section>
          </>
        )
      })}

      <section className="section section--border-top" id="omgeving">
        <div className="wrapper">
          <div className="grid grid--2-1">
            <div className="block block--center block--clean">
              <h5 className="block-subline">{area.nodes[0].eyebrow}</h5>
              <h4 className="block-headline">{area.nodes[0].title}</h4>
              <ReactMarkdown children={area.nodes[0].content} />
              <a className="block-cta button button--tertiary button--green"
                 href="https://www.google.com/maps/place/Residence+Bommenede/@51.7260252,3.9362888,15z/data=!4m5!3m4!1s0x0:0xba1dc9f63d46ddd4!8m2!3d51.7260252!4d3.9362888" target="_blank">
                {area.nodes[0].button}
              </a>
            </div>
            <div className="block block--image" style={{
              backgroundImage: `url(${area.nodes[0].galleryImages[0]})`
            }}>
            </div>
          </div>
        </div>
      </section>

      <section className="section section--brown section--expadding-bot" id="vakantiewoningen">
        <div className="wrapper">
          <header className="section__header">
            <h3 className="section__header-headline">{chalet.nodes[0].headline}</h3>
            <p className="section__header-text">{chalet.nodes[0].text}</p>
          </header>
          <div className="grid grid--3-1">
            <div className="block block--image">
              <Splide hasTrack={ false } aria-label="ChaletImages">
                <SplideTrack>
                  {chaletImages && chaletImages.nodes.map((item) => {
                    return (
                      <SplideSlide>
                        <img src={item.image} height={680} alt={item.name} />
                      </SplideSlide>
                    )
                  })}
                </SplideTrack>
                <div className="splide__arrows">
                  <button className="splide__arrow splide__arrow--prev">
                    <span className="material-icons-round">chevron_left</span>
                  </button>
                  <button className="splide__arrow splide__arrow--next">
                    <span className="material-icons-round">chevron_right</span>
                  </button>
                </div>
              </Splide>
            </div>
            <div className="block block--center block--white block--shadow block--chalets">
              <h5 className="block-subline">{chalet.nodes[0].eyebrow}</h5>
              <h4 className="block-headline">{chalet.nodes[0].title}</h4>
              <p className="block-text">{chalet.nodes[0].content}</p>
              <Link className="block-cta button button--primary" to={'/vakantiewoningen'}>
                Reserveer
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="wrapper">
          <div className="grid">
            <div className="block block--clean">
              <h5 className="block-subline">{faq.nodes[0].eyebrow}</h5>
              <h4 className="block-headline">{faq.nodes[0].title}</h4>
              <p className="block-text">{faq.nodes[0].content}</p>
            </div>
            <div className="block block--clean">
              {faqs && faqs.nodes.map((item) => {
                return (
                  <div className="faq block--green">
                    <button onClick={() => setOpenFaq(item.question)}>
                      <span>{item.question}</span><i className="material-icons-round">{openFaq === item.question ? 'expand_less' : 'expand_more'}</i>
                    </button>
                    {openFaq === item.question && (
                      <div className="faq-answer">
                        <ReactMarkdown children={item.answer} />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="section section--grey section--expadding-bot">
        <div className="wrapper">
          <div className="grid">
            <div className="block block--image" style={{
              backgroundImage: `url(${reviews.nodes[0].image})`
            }}>
            </div>
            <div className="review block block--white block--shadow block--center">
              <h4 className="review-text">
                {reviews.nodes[0].review}
              </h4>
              <p className="review-author">
                {reviews.nodes[0].author}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section" id="contact">
        <div className="wrapper">
          <div className="grid grid--1-1-1">
            <div className="block block--green grid--contact-1">
              <div className="block-spacing">
                <h4 className="block-headline">Openingstijden camping</h4>
                <p className="block-text">Geopend van 15 maart t/m 1 november<br />Aankomsttijd vanaf 12:00<br />Vertrektijd uiterlijk 12:00</p>
              </div>
              <div className="block-spacing">
                <h4 className="block-headline">De receptie</h4>
                <p className="block-text">Geopend van 09:00 – 17:00 en 19:00 – 20:00</p>
              </div>
              <div className="block-spacing">
                <h4 className="block-headline">Reserveren</h4>
                <p className="block-text">U kunt telefonisch reserveren voor de camping of een vakantie woning. Voor een van de vakantie woningen kunt u ook online reserveren.</p>
                <a className="block-cta button button--primary"
                   href="/vakantiewoningen">
                  Reserveer
                </a>
              </div>
            </div>
            <div className="block block--grey grid--contact-2">
              <div>
                <h4 className="block-headline">Ons adres</h4>
                <address className="block-text">Kijkuitsedijk 1<br />4316 PN<br />Zonnemaire</address>
              </div>
            </div>
            <div className="block block--white block--shadow grid--contact-3">
              <div>
                <h4 className="block-headline">Neem contact op</h4>
                <p className="block-text">
                  +31 (0) 6 20 354 744
                </p>
                <p className="block-text">
                  info@oudbommenede.nl
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="wrapper">
          &copy; campingoudbommenede.nl
        </div>
      </footer>
    </>
  )
};

export const query = graphql`
    query SectionsQuery {
        allSectionsJson {
            nodes {
                name
                title
                content
                block_one {
                    content
                    eyebrow
                    title
                    galleryImages
                }
                block_two {
                    content
                    eyebrow
                    title
                    galleryImages
                }
            }
        }
        allAreaJson {
            nodes {
                eyebrow
                title
                content
                button
                galleryImages
            }
        }
        allChaletJson {
            nodes {
                headline
                text
                eyebrow
                title
                content
                button
                image
            }
        }
        allFaqJson {
            nodes {
                eyebrow
                title
                content
            }
        }
        allFaqsJson {
            nodes {
                question
                answer
            }
        }
        allReviewsJson {
            nodes {
                image
                review
                author
            }
        }
        allChaletImagesJson {
            nodes {
                image
                chalet
                name
            }
        }
    }
`;

export default Home;