import React from 'react';
import Navigation from '../Navigation/Navigation';
import { StaticQuery, graphql, Link } from 'gatsby';

const Hero = ({ isScrolled }) => {
  return (
    <StaticQuery
      query={graphql`
        query HeroQuery {
          allHeroJson {
            nodes {
              name
              title
              image
              logo
            }
          }
        }
    `}
      render={(data) => {
        const { allHeroJson: hero } = data;
        const name = hero.nodes[0].name;
        const title = hero.nodes[0].title;
        const image = hero.nodes[0].image;
        const logo = hero.nodes[0].logo;

        return (
          <div className={ isScrolled ? 'hero is-scrolled' : 'hero' } id="home" style={{
            backgroundImage: `url(${image})`
          }}>
            <Navigation logo={logo} />
            <header className="hero__header">
              <h1 className="hero__header-headline">{name}</h1>
              <h2 className="hero__header-subline">{title}</h2>
              <div className="hero__header-cta">
                <Link className="button button--secondary" to={'#camping'}>
                  Bekijk de camping
                </Link>
                <Link className="button button--primary" to={'/vakantiewoningen'}>
                  Reserveer
                </Link>
              </div>
            </header>
          </div>
        )
      }}
    />
  )
};

export default Hero;